<template>
  <div class="Body">
    <h3>El mundo tal como lo conocemos</h3>
  </div>
  <div>
    <button onclick="start()">Iniciar</button>
  </div>
</template>
<script>
// create button start()

</script>