<template>
  <NavMenu />
  <HelloWorld />
  <About />
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";
import NavMenu from "./components/NavMenu.vue";
import About from "./components/AboutFooter.vue";
//import LoginOn from "./components/LoginOn.vue";
export default {
  name: "App",
  components: {
    HelloWorld,
    /*LoginOn,*/
    NavMenu,
    About,
  },
};
</script>
<!-- AMP Analytics -->

<style scope>
@import url("https://fonts.googleapis.com/css2?family=Caveat&family=Poppins:ital,wght@1,100&display=swap");
#app {
  font-family: "Caveat", cursive;
  /*font-family: 'Poppins', sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  margin: 0;
}
</style>
