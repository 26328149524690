<template>
  <head></head>
  <div class="nav">
    <div class="nav_text">
      <img src="../../src/assets/Logo.svg" alt="" />
    </div>
    <div class="search">
      <input type="text" placeholder="Search" />
      <label for="search"><a href="http://"><img src="../assets/magnifying-glass.png" alt=""></a></label>
    </div>
    <div class="nav_menu">
      <div class="nav_menu-bar">
        <a href="http://">Home</a>
        <a href="http://">Store</a>
        <a href="http://">About</a>
      </div>
      <a href="./ShoppinCart.vue"
        ><img src="../assets/shopping-cart (1).png"
      /></a>
      <a href="./LoginOn.vue"><img src="../assets/login.png" alt="" /></a>
    </div>
  </div>
</template>
<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.nav {
  margin: 0;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #110e0e;
  color: #fff;
}
.search {
  display: flex;
  align-items: center;
  align-items: center;;
}
.search img {
  width: 13px;
  height: 13px;
}
.search input {
  width: 200px;
  height: 30px;
  border: none;
  border-radius: 5px;
  padding-left: 10px;
  margin-right: 10px ;
}
.nav_text {
  margin-left: 20px;
}
.nav_menu {
  justify-content: stretch;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: 20px;
}
.nav_menu-bar {
  font-size: 20px;
  display: flex;
  gap: 20px;
}

.nav_menu-bar a {
  text-decoration: none;
  color: #13abc6;
}
.nav_menu img {
  width: 60px;
  color: #fff;
}

.nav_text img {
  width: 100px;
  height: 90px;
}
</style>
